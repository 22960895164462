import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const WhyCoastalPage = (props) => (
  <Layout location={props.location}>
    <SEO title="Why Coastal?" />
    <h1 style={styles.header}>Why Coastal?</h1>
    <div style={styles.body}>
      <h3 style={{...styles.italic, ...styles.primaryText}}>Because Your Dreams Deserve Our Full Attention.</h3>
      <p>
        If your dreams have been challenged by the recent changes in our economy and erratic market activity we may be 
        able to help you right your course.
      </p>
      <p>
        We take the time to understand who you are, how you want to live today, and the legacy you want to leave 
        tomorrow — then apply decades of business experience and independent research to every decision we make. Our 
        financial solutions are delivered in a style that is both comfortable and comforting, based on the core values 
        that we live by in life and in business.
      </p>
      <p>
        Our goal is to deliver highly personal wealth management customized to your risk, goals, and today's challenges.
      </p>
      <p>
        Michael Mueller, President and Captain of our Ship, provides unparalleled personal financial advisory. It's no 
        surprise to current clients and colleagues that he was honored four consecutive years as a Five Star Wealth 
        Manager. He combines financial acumen with a personal concern for each and every client. Even in volatile 
        economic times, he strives to steer clients toward their financial dreams.
      </p>
      <a href="/contact">Give us a call. We'll sit down, listen to your financial needs and see how we can help.</a>v  Call <a href="tel:+15136191100">513-619-1100,</a> extension 6 for Michael or extension 2 for Jay.
    </div>
  </Layout>
)

let styles={
  header: {
    color: '#8d1b38',
    padding: '35px 15px 0 25px'
  },
  primaryText: {
    color: '#8d1b38'
  },
  bullet: {
    paddingLeft: '30px'
  },
  secondaryText: {
    color: '#083f95',
    fontWeight: '700'
  },
  italic: {
    fontStyle: 'italic'
  },
  body: {
    padding: '0 60px 30px 60px',
    color: 'black'
  }
}

export default WhyCoastalPage
